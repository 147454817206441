const skills: string[] = [
  "HTML5",
  "CSS3",
  "Javscript",
  "jQuery",
  "Typescript",
  "Vue",
  "Tailwind",
  "NextJs",
  "C#",
  "Google script",
  "Flutter",
  "React",
  "Redux",
  "Laravel",
  "Node.js",
  "Express",
  "Firebase",
  "MySQL",
  "MongoDB",
  "Google Cloud Platform",
  "Amazon Web Services",
  "CloudFlare",
  "Unit Testing",
  "MVC",
  "Git",
  "Fastlane",
  "Responsive Design",
  "MVVM",
  "C++",
  "Dart",
  "GSAP",
  "PHP",
];
export default skills; 
