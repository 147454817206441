import styles from "./about.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "../../../shared/components/sectionheader/sectionheader";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";

import Typewriter from "typewriter-effect";
import TagCloud from "./components/tagcloud";
import { Element, Link } from "react-scroll";
// import Fade from "react-reveal/Fade";

const About = () => {
  return (
    <Element name="about">
      <div className={styles["about-wrapper"]}>
        <SectionHeader title={"About"} />
        {/* <Fade duration={1200}> */}
          <Container fluid className={styles["about-content"]}>
            <Row>
              <Col md={6} className={styles["info-wrapper"]}>
                <div className={styles["about-text"]}>
                  <h2 className={styles["section-header"]}>Who am I?</h2>
                  <p>Hi, i'm Rehan 👶🏻</p>
                  <p>
                    ⚡️ A passionate 👨‍💻Full Stack Web & 📱Mobile developer
                    employing the best practices across numerous tech stacks.
                  </p>
                  <p>
                    ⚡️ You can find me working with ReactJs, NextJs, Flutter, VueJs,
                    Laravel among many others.
                  </p>
                  <p>⚡️ Gaming geek.🕹️</p>

                  <div className={styles["i-love-section"]}>
                    <p style={{ paddingRight: "4px" }}>⚡️ I love</p>
                    <span>
                      <Typewriter
                        options={{
                          strings: [
                            "learning new technologies💡",
                            "experimenting with different design patterns",
                            "solving complex problems",
                            "fixing hard-to-fix bugs 🛠",
                            "collaborating with others 🤝",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </div>
                </div>

                <div className={styles["about-summary"]}>
                  <p>
                  In 2014, I graduated and briefly explored coding as a curiosity. However, it wasn't later until I secured my first job as a programmer, that I fully embraced this as my <Link to="experience" className={styles["link"]}>career</Link> path. Since then, it's been an exciting journey, delving into new technologies, working on fascinating <Link to="projects" className={styles["link"]}>projects</Link>, and collaborating with talented individuals.
                    
                  </p>
                </div>

                <div className={styles["location-wrapper"]}>
                  <LocationIcon />
                  <p>Abu Dhabi, UAE</p>
                </div>
              </Col>
              <Col md={6} className={styles["word-cloud-section"]}>
                <h2
                  className={
                    styles["skills-header"] + " " + styles["section-header"]
                  }
                >
                  Skills
                </h2>
                <div className={styles["word-cloud-wrapper"]}>
                  <TagCloud />
                </div>
              </Col>
            </Row>
          </Container>
        {/* </Fade> */}
      </div>
    </Element>
  );
};

export default About;
