import ExperienceModel from "../models/experience";
import Assets from "../utils/assets";

const experiences: ExperienceModel[] = [
  {
    positionTitle: "Front-End Engineer",
    companyName: "Sonorbi IT FZE DMCC",
    companyUrl: "https://www.suadeo.com/",
    companyLogo: Assets.SUADEO_LOGO,
    companyBackgroundUrl: Assets.SUADEO_BANNER,

    responsibilites: [
      "Develop and maintain Flutter tablet applications for various devices",
      "Utilize DevExpress for web UI/UX",
      "Manage version control with SVN for efficient collaboration",
      "Ensure cross-browser compatibility and responsive design",
    ],
    dateFrom: "Dec 2023",
    dateTo: "Current",
    stacks: [
      {
        name: "Flutter",
        logoPath: Assets.FLUTTER_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "ReactJs",
        logoPath: Assets.REACT_LOGO,
        logoInversionColorLevel: 0.8,
      },
      {
        name: "Figma",
        logoPath: Assets.FIGMA_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "SVN",
        logoPath: Assets.SVN,
        logoInversionColorLevel: 0.8,
      },
      {
        name: "jQeury",
        logoPath: Assets.JQUERY_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "DevExpress",
        logoPath: Assets.DEV_EXPRESS,
        logoInversionColorLevel: 0,
      },
    ],
  },
  {
    positionTitle: "Front-End Engineer",
    companyName: "Aqary International",
    companyUrl: "https://www.aqaryint.com/",
    companyLogo: Assets.AQARY_LOGO,
    companyBackgroundUrl: Assets.AQARY_BANNER,

    responsibilites: [
      "Develop high-performance web applications using NextJs",
      "Develop reusable React components",
      "Efficiently manage state with Redux and enhance styling using Sass",
      "Collaborate and organize tasks with Trello for effective project management",
    ],
    dateFrom: "Sep 2023",
    dateTo: "Nov 2023",
    stacks: [
      {
        name: "NextJs",
        logoPath: Assets.NEXT_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "ReactJs",
        logoPath: Assets.REACT_LOGO,
        logoInversionColorLevel: 0.8,
      },
      {
        name: "Typescript",
        logoPath: Assets.TYPSCRIPT_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "Trello",
        logoPath: Assets.TRELLO_LOGO,
        logoInversionColorLevel: 0.8,
      },
      {
        name: "Redux",
        logoPath: Assets.REDUX_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "Sass",
        logoPath: Assets.SASS_LOGO,
        logoInversionColorLevel: 1,
      },
    ],
  },
  {
    positionTitle: "Full-Stack Engineer",
    companyName: "Maxima Group",
    companyUrl: "https://maximagroup.ae/",
    companyLogo: Assets.MAXIMA_LOGO,
    companyBackgroundUrl: Assets.MAXIMA_BANNER,

    responsibilites: [
      "Develop responsive web applications & design admin control panels using Laravel and VueJs",
      "Implement complex designs & animations",
      // "Reponsive Design and create reusable widgets/components",
      "Integrate API's and handle dynamic SEO management",
    ],
    dateFrom: "Mar 2023",
    dateTo: "Aug 2023",

    stacks: [
      {
        name: "Laravel",
        logoPath: Assets.LARAVEL_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "MySql",
        logoPath: Assets.MYSQL_LOGO,
        logoInversionColorLevel: 0,
      },
      {
        name: "VueJs",
        logoPath: Assets.VUE_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "Adobe XD",
        logoPath: Assets.ADOBE_XD_LOGO,
        logoInversionColorLevel: 0.2,
      },
      {
        name: "GSAP",
        logoPath: Assets.GSAP_LOGO,
        logoInversionColorLevel: 1,
      },

      {
        name: "Git",
        logoPath: Assets.GIT_LOGO,
        logoInversionColorLevel: 1,
      },
    ],
  },
  {
    positionTitle: "Full-Stack Engineer",
    companyName: "MVP Applications and Game Design",
    companyUrl: "https://mvp-apps.com",
    companyLogo: Assets.MVP_LOGO,
    companyBackgroundUrl: Assets.MVP_BANNER,
    responsibilites: [
      "Restructuing of equestrian sports application",
      "Utilize clean and maintainable architecture",
      "Creating RESTful and GraphQL APIs",
      // "Optimize SQL queries for faster response times",
      "Understand client's requirements, and designing a workflow with the team",
    ],
    dateFrom: "Dec 2020",
    dateTo: "Feb 2023",
    stacks: [
      {
        name: "Laravel",
        logoPath: Assets.LARAVEL_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "Firebase",
        logoPath: Assets.FIREBASE_LOGO,
        logoInversionColorLevel: 0.9,
      },
      {
        name: "VueJs",
        logoPath: Assets.VUE_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "Flutter",
        logoPath: Assets.FLUTTER_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "MySql",
        logoPath: Assets.MYSQL_LOGO,
        logoInversionColorLevel: 1,
      },
      {
        name: "CloudFlare",
        logoPath: Assets.CLOUDFLARE_LOGO,
        logoInversionColorLevel: 1,
      },
    ],
  },
];

export default experiences;
